@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,200;0,400;0,600;0,700;0,800;1,200;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  
    html {
        @apply font-fira;
    }
    .paralel-bars{
        @apply px-24;
    }
    .paralel-bars-sm{
        @apply px-14;
    }
}
.gradientTitleCourses {
    @apply bg-gradient-to-r from-secondary to-white border-none;
}
.gradientTitleTours {
    @apply bg-gradient-to-r from-indigo to-white border-none;
}
.gradientButtonCourses {
    @apply bg-gradient-to-r from-secondary to-indigo border-none;
}
.gradientButtonTours {
    @apply bg-gradient-to-r from-indigo to-green border-none;
}


/* .btn-hero {
    @apply bg-gradient-to-r from-secondary to-indigo border-none;
} */
/* .btn-card {
    @apply text-white bg-gradient-to-r from-secondary to-indigo border-none hover:bg-primary;
}
.btn-tour {
    @apply bg-gradient-to-r from-secondary to-grey-regular
    text-white;
} */
.active {
    @apply border-b-4 border-white bg-transparent;
}
.bg-waves {
    background-image: url('./assets/wave-1.png');
    background-size: contain;
    background-repeat: no-repeat;
}


.bg-mesh {
    background-image: url('./assets/mesh-238.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-layered-waves{
    background-image: url('./assets/layered-waves.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
.bg-stacked-waves{
    background-image: url('./assets/stacked-waves.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.d2 {
    animation-delay: 0.05s;
}
.d3 {
    animation-delay: 0.1s;
}

@keyframes gradientChange {
    to {
        background-position: 100%;
    }
}
.animate-bg-positions {
    background-size: 200%;
    animation: gradientChange 2s ease infinite alternate-reverse;
}
.animate-bg {
    background-size: 120%;
    animation: gradientChange 5s ease infinite alternate-reverse;
}
.bg-size-200 {
    background-size: 120%;
}
.bg-size-100 {
    background-size: 100%;
}
.btn-hover {
    @apply hover:scale-[1.1] hover:drop-shadow-xl;
}
